<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Paramètres",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      baseTemplateDownloadUrl: process.env.VUE_APP_BASE_TEMPLATES_URL,
      showUploadModal: false,
      title: "Paramètres",
      uploadErrors: [],
      dataTypes: [
        {
          id: 'suppliers',
          name: 'Prestataires',
          fileName : 'camplus_suppliers_import_template.xlsx',
        },
        {
          id: 'internal_employees',
          name: 'Employés',
          fileName : 'camplus_employees_import_template.xlsx',
        },
        {
          id: 'supplier_employees',
          name: 'Employés Prestataire',
          fileName : 'camplus_suppliers_employees_import_template.xlsx',
        },
        {
          id: 'kitchens',
          name: 'Cuisisnes',
          fileName : 'camplus_kitchens_import_template.xlsx',
        },
        {
          id: 'visitors',
          name: 'Visiteurs',
          fileName : 'camplus_visitors_import_template.xlsx',
        },
        {
          id: 'warehouses',
          name: 'Magasins',
          fileName : 'camplus_warehouses_import_template.xlsx',
        },
        {
          id: 'equipments',
          name: 'Equipements',
          fileName : 'camplus_equipments_import_template.xlsx',
        },
        {
          id: 'kitchens',
          name: 'Cuisisnes',
          fileName : 'camplus_kitchens_import_template.xlsx',
        },
        {
          id: 'rooms',
          name: 'Chambres',
          fileName: 'camplus_rooms_import_template.xlsx'
        },
        {
          id: 'categories',
          name: 'Catégories',
          fileName: 'camplus_product_categories_import_template.xlsx'
        },
        {
          id: 'products',
          name: 'Produits',
          fileName: 'camplus_products_import_template.xlsx'
        },
      ],
      uploadForm: {
        dataType: "",
        uploadedFile: "",
      },
      uploadResult : {
        success : true,
        errorMsg: "",
      },
      items: [
        {
          text: "Paramètres",
          active: true,
        },
      ],
    };
  },

  methods: {
    onFileChange(event){
      this.uploadForm.uploadedFile = event.target.files[0];
    },
    logAccessClick(){
      var url = process.env.VUE_APP_BASE_URL + 'system/access_log';
      window.open(url);
      // alert(url);
    },  
    startFileUploading(){
      let formData = new FormData();        
        formData.append("uploadedFile", this.uploadForm.uploadedFile);
        formData.append("uploadedFileType", this.uploadForm.dataType.id);
        var loader = this.$loading.show();
        this.$http.post("/uploads/handleFileUpload", formData)
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.showUploadModal = false;
                break;

              case 500:
                loader.hide();
                this.uploadErrors = res.data.original.errors;
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row settings">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.general' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/general.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Paramètres <br />
                          généraux
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.dropdowns.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/list.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Listes <br />
                          déroulantes
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link
                  :to="{ name: 'base.settings.hse_criterias.index' }"
                >
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/hse.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Critères <br />
                          HSE
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.validation_levels.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/diagram_levels.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Niveaux <br />
                          De validation
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.notifications.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/notification.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Notifications <br />
                          & Workflow
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.flow_and_ops.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/workflow.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Codification <br />
                          & Opérations
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'settings.users.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/user.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Comptes <br />
                          Utilisateurs
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'settings.roles.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/permission.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">Roles <br />& Permissions</h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-md-2 setting-item">
                <router-link :to="{ name: 'base.settings.technical.index' }">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/tech.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Paramètres <br />
                          techniques
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="col-md-2 setting-item">
                <router-link :to="{name: 'settings.access_log.index'}">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="
                            require('@/assets/images/base/settings/log-file.png')
                          "
                          class="rounded-0"
                          alt=""
                        />
                        <h4 class="card-title">
                          Log <br />
                          D'accès
                        </h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>

              <div
                @click="showUploadModal = true"
                class="col-md-2 setting-item"
              >
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">
                      <img
                        :src="require('@/assets/images/base/menu/upload.png')"
                        class="rounded-0"
                        alt=""
                      />
                      <h4 class="card-title">
                        Importation <br />
                        de données
                      </h4>
                    </h4>
                    <p class="card-text">
                      <!-- With supporting text below as a natural lead-in to
                      additional content. -->
                    </p>
                  </div>
                </div>
              </div>

              <Modal
                v-model="showUploadModal"
                title="Importation de données."
              >
                <form @submit.prevent="startFileUploading" enctype="multipart/form-data">
                  <b-alert v-if="uploadErrors.length > 0" show variant="danger">
                    <ul>
                      <li v-for="(uploadError, index) in uploadErrors" v-bind:key="index"><span v-html="uploadError"></span></li>
                    </ul>
                  </b-alert>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="">Type:</label>
                          <multiselect
                            :searchable="true"
                            v-model="uploadForm.dataType"
                            track-by="id"
                            label="name"
                            :options="dataTypes"
                            placeholder="Select Type"
                            :select-label="''"
                            :allow-empty="false"
                          >
                            <template slot="singleType" slot-scope="{ type }">{{
                              type.name
                            }}</template>
                          </multiselect>
                        </div>

                        <div class="col-md-6">
                          <label for="">Télécharger le fichier modèle:</label> <br>
                          <a target="_blank" :href="baseTemplateDownloadUrl + '?file='+uploadForm.dataType.fileName">{{ uploadForm.dataType.fileName }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-2 mb-2">
                      <label for="">Fichier:</label>
                      <input type="file" @change="onFileChange" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" class="form-control" name="" id="">
                    </div>
                    
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right" type="submit">
                        <i class="fas fa-file-upload"></i>
                        Lancer l'Importation
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
