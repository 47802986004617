var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row settings" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "base.settings.general" } } },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/general.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Paramètres "),
                                  _c("br"),
                                  _vm._v(" généraux ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "base.settings.dropdowns.index" }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/list.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Listes "),
                                  _c("br"),
                                  _vm._v(" déroulantes ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "base.settings.hse_criterias.index" }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/hse.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Critères "),
                                  _c("br"),
                                  _vm._v(" HSE ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "base.settings.validation_levels.index"
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/diagram_levels.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Niveaux "),
                                  _c("br"),
                                  _vm._v(" De validation ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "base.settings.notifications.index" }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/notification.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Notifications "),
                                  _c("br"),
                                  _vm._v(" & Workflow ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "base.settings.flow_and_ops.index" }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/workflow.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Codification "),
                                  _c("br"),
                                  _vm._v(" & Opérations ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "settings.users.index" } } },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/user.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Comptes "),
                                  _c("br"),
                                  _vm._v(" Utilisateurs ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "settings.roles.index" } } },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/permission.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Roles "),
                                  _c("br"),
                                  _vm._v("& Permissions")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "base.settings.technical.index" }
                          }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/tech.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Paramètres "),
                                  _c("br"),
                                  _vm._v(" techniques ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2 setting-item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "settings.access_log.index" } }
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _c("img", {
                                  staticClass: "rounded-0",
                                  attrs: {
                                    src: require("@/assets/images/base/settings/log-file.png"),
                                    alt: ""
                                  }
                                }),
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(" Log "),
                                  _c("br"),
                                  _vm._v(" D'accès ")
                                ])
                              ]),
                              _c("p", { staticClass: "card-text" })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-2 setting-item",
                      on: {
                        click: function($event) {
                          _vm.showUploadModal = true
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h4", { staticClass: "card-title" }, [
                            _c("img", {
                              staticClass: "rounded-0",
                              attrs: {
                                src: require("@/assets/images/base/menu/upload.png"),
                                alt: ""
                              }
                            }),
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(" Importation "),
                              _c("br"),
                              _vm._v(" de données ")
                            ])
                          ]),
                          _c("p", { staticClass: "card-text" })
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "Modal",
                    {
                      attrs: { title: "Importation de données." },
                      model: {
                        value: _vm.showUploadModal,
                        callback: function($$v) {
                          _vm.showUploadModal = $$v
                        },
                        expression: "showUploadModal"
                      }
                    },
                    [
                      _c(
                        "form",
                        {
                          attrs: { enctype: "multipart/form-data" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.startFileUploading($event)
                            }
                          }
                        },
                        [
                          _vm.uploadErrors.length > 0
                            ? _c(
                                "b-alert",
                                { attrs: { show: "", variant: "danger" } },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.uploadErrors, function(
                                      uploadError,
                                      index
                                    ) {
                                      return _c("li", { key: index }, [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(uploadError)
                                          }
                                        })
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-6" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Type:")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.dataTypes,
                                        placeholder: "Select Type",
                                        "select-label": "",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "singleType",
                                          fn: function(ref) {
                                            var type = ref.type
                                            return [_vm._v(_vm._s(type.name))]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.uploadForm.dataType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.uploadForm,
                                            "dataType",
                                            $$v
                                          )
                                        },
                                        expression: "uploadForm.dataType"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-6" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Télécharger le fichier modèle:")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          _vm.baseTemplateDownloadUrl +
                                          "?file=" +
                                          _vm.uploadForm.dataType.fileName
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.uploadForm.dataType.fileName)
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "col-md-12 mt-2 mb-2" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Fichier:")
                              ]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "file",
                                  accept:
                                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                  name: "",
                                  id: ""
                                },
                                on: { change: _vm.onFileChange }
                              })
                            ]),
                            _c("div", { staticClass: "col-md-12" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary float-right",
                                  attrs: { type: "submit" }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-file-upload"
                                  }),
                                  _vm._v(" Lancer l'Importation ")
                                ]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }